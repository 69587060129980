import React, { ReactElement } from "react";
import { Marker } from "react-leaflet";
import L from "leaflet";
import { useTranslation } from "react-i18next";
import classes from "./CurrentPositionMarker.module.css";

type CurrentPositionMarkerProps = {
    coords: GeolocationCoordinates | undefined;
};

function CurrentPositionMarker({
    coords,
}: CurrentPositionMarkerProps): ReactElement | null {
    const { t } = useTranslation();

    const a = 56;
    const cr = a / 2;
    const markerCr = a / 7;
    const tipOffset = 2.5 * markerCr;

    const icon = new L.DivIcon({
        iconSize: new L.Point(a, a),
        className: "override-leaflet-styles",
        html: `
            <svg
                aria-label="${t("map.ariaLabels.currentPosition")}"
                width="${a}px"
                height="${a}px"
                viewBox="0 0 ${a} ${a}"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                style = "transform: rotateZ(${coords?.heading}deg);"
            >
                <defs>
                    <mask id="extended-marker">
                        <rect
                            x="0"
                            y="0"
                            width="${a}"
                            height="${a}"
                            fill="white"
                        />
                        <circle
                            cx="${cr}"
                            cy="${cr}"
                            r="${markerCr * Math.SQRT2}"
                            fill="black"
                        />
                    </mask>
                </defs>
                <circle
                    cx="${cr}"
                    cy="${cr}"
                    r="${cr}"
                    class="${classes["bg-circle"]}"
                />
                <circle
                    cx="${cr}"
                    cy="${cr}"
                    r="${cr}"
                    class="${classes["pulse-animation"]}"
                    style="transform-origin: ${cr}px ${cr}px;"
                />
                <circle
                    cx="${cr}"
                    cy="${cr}"
                    r="${markerCr}"
                    class="${classes.marker}"
                />
                ${
                    coords?.heading &&
                    `<path
                        d="
                            M ${cr - markerCr} ${cr - markerCr}
                            L ${cr} ${cr - tipOffset}
                            L ${cr + markerCr} ${cr - markerCr} Z
                        "
                        class=${classes.tip}
                        mask="url(#extended-marker)"
                    />`
                }
            </svg>
        `,
    });

    return coords?.latitude && coords?.longitude ? (
        <Marker
            position={[coords.latitude, coords.longitude]}
            icon={icon}
            alt="position-marker"
        />
    ) : null;
}

export default CurrentPositionMarker;
