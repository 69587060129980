import React, { ReactElement } from "react";
import {
    ActionIcon,
    Collapse,
    Group,
    Stack,
    Text,
    useComputedColorScheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
    IconBvgInformationCircleSolid as InformationCircleActive,
    IconBvgInformationCircleLine as InformationCircle,
} from "../../GeneratedIcons/Icons";

import classes from "./CollapsibleInfoItem.module.css";

const COLLAPSIBLE_DESCRIPTION_GAP = 8;
const ICON_SIZE = 22;

type CollapsibleInfoItemProps = {
    icon: ReactElement;
    title: string;
    description?: string;
    openAriaLabel?: string;
    closeAriaLabel?: string;
};

function CollapsibleInfoItem({
    icon,
    title,
    description,
    openAriaLabel,
    closeAriaLabel,
}: CollapsibleInfoItemProps): ReactElement {
    const [opened, { toggle }] = useDisclosure(false);
    const colorScheme = useComputedColorScheme("light");
    const informationCircleColor =
        colorScheme === "dark" ? "var(--mantine-color-white-4)" : "";

    return (
        <Stack gap={COLLAPSIBLE_DESCRIPTION_GAP}>
            <Group justify="space-between" wrap="nowrap" onClick={toggle}>
                <Group gap="var(--canteen-info-group-gap)" wrap="nowrap">
                    <Group>{icon}</Group>
                    <Text className={classes.title} size="sm">
                        {title}
                    </Text>
                </Group>
                {description && (
                    <ActionIcon variant="transparent">
                        {opened ? (
                            <InformationCircleActive
                                ariaLabel={closeAriaLabel}
                                size={ICON_SIZE}
                                color={informationCircleColor}
                            />
                        ) : (
                            <InformationCircle
                                ariaLabel={openAriaLabel}
                                size={ICON_SIZE}
                                color={informationCircleColor}
                            />
                        )}
                    </ActionIcon>
                )}
            </Group>
            {description && (
                <Collapse in={opened}>
                    <Text className={classes.description} size="sm">
                        {description}
                    </Text>
                </Collapse>
            )}
        </Stack>
    );
}

export default CollapsibleInfoItem;
