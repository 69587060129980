import React, { ReactElement, useContext } from "react";
import {
    Group,
    Stack,
    Text,
    useMantineTheme,
    lighten,
    SimpleGrid,
    ScrollArea,
    Tooltip,
    ActionIcon,
    useMantineColorScheme,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useClipboard, useViewportSize } from "@mantine/hooks";
import BottomSheet from "../../reusableComponents/BottomSheet/BottomSheet";
import {
    Canteen,
    getCompanyColor,
    getPrettyDistance,
} from "../../services/canteensService";
import {
    IconBvgPhoneLine as IconPhone,
    IconLocationMarkerLine as IconMapPin,
    IconBvgCopyLine as IconCopy,
    IconBvgClockLine as IconClock,
    IconBvgArrowRightUpDiagonaleLine as ArrowRightUpDiagonalIcon,
    IconBvgKeyLine as AccessIcon,
    IconBvgMoneyCashLine as PaymentIcon,
    IconBvgPersonWheelchairLine as AccessibleIcon,
} from "../../GeneratedIcons/Icons";

import BottomSheetFooter from "../../reusableComponents/BottomSheet/BottomSheetFooter";
import Button from "../../reusableComponents/Button/Button";
import CanteenDetailsHeader from "./CanteenDetailsHeader";
import classes from "./CanteenDetails.module.css";
import CollapsibleInfoItem from "../../projectComponents/CollapsibleInfoItem/CollapsibleInfoItem";
import { LocationContext } from "../../providers/LocationProvider";

const BERLIN_AREA_CODE = "030";
const INFORMATION_CONTAINER_GAP = 0;
const BERLIN_STRING = "Berlin";

type CanteenDetailsProps = {
    canteen: Canteen | null;
    onClose: () => void;
    onShowRoute: () => void;
};

function CanteenDetails({
    canteen,
    onClose,
    onShowRoute,
}: CanteenDetailsProps): ReactElement | null {
    const ICON_SIZE = 24;
    const theme = useMantineTheme();
    const { t } = useTranslation();
    const { colorScheme } = useMantineColorScheme();
    const isDarkMode = colorScheme === "dark";
    const { height: viewportHeight } = useViewportSize();
    const { hasCoords } = useContext(LocationContext);

    const clipboard = useClipboard({ timeout: 700 });

    if (!canteen) {
        return null;
    }

    const companyColor = getCompanyColor(theme, canteen.owner);
    const companyColorButton = lighten(companyColor, 0.4);

    const copyAddress = () => {
        clipboard.copy(
            `${canteen.street} ${canteen.postalCode} ${BERLIN_STRING}`
        );
    };

    return (
        <BottomSheet
            isOpen={!!canteen}
            onClose={onClose}
            closeOnDrag
            contentMarginStyle={{ margin: "0 16px" }}
            backdropAriaLabel={t(
                "canteenDetails.ariaLabels.bottomSheetBackdrop"
            )}
        >
            <>
                <CanteenDetailsHeader canteen={canteen} />

                {canteen.closingDate && (
                    <Text size="sm" className={classes["info-banner"]}>
                        {t("canteenDetails.closingInfo", {
                            closingDate: new Date(
                                canteen.closingDate
                            ).toLocaleDateString("de-DE"),
                        })}
                    </Text>
                )}

                <ScrollArea.Autosize
                    mah={viewportHeight * 0.42}
                    type="always"
                    className={classes.scrollarea}
                >
                    <Stack className={classes.container}>
                        <Group
                            gap="var(--canteen-info-group-gap)"
                            align="flex-start"
                        >
                            <IconClock
                                ariaLabel={t("canteenDetails.openingHours")}
                                size={ICON_SIZE}
                            />
                            <Stack gap={INFORMATION_CONTAINER_GAP} ml={12}>
                                <Text fw="bold" size="sm">
                                    {t("canteenDetails.openingHours")}
                                </Text>

                                <Stack>
                                    {canteen.openingHours.map(
                                        (openingHours, index) => (
                                            <Group
                                                align="flex-start"
                                                key={`${
                                                    canteen.id
                                                }_days_${index.toString()}`}
                                            >
                                                <Text
                                                    className={classes.days}
                                                    size="sm"
                                                >
                                                    {openingHours.days}
                                                </Text>
                                                <Stack
                                                    gap={
                                                        INFORMATION_CONTAINER_GAP
                                                    }
                                                >
                                                    {openingHours.hours.map(
                                                        (block, hoursIndex) => (
                                                            <Text
                                                                size="sm"
                                                                className={
                                                                    classes[
                                                                        "compact-stack"
                                                                    ]
                                                                }
                                                                key={`${
                                                                    canteen.id
                                                                }_hours_${hoursIndex.toString()}`}
                                                            >
                                                                {block}
                                                            </Text>
                                                        )
                                                    )}
                                                </Stack>
                                            </Group>
                                        )
                                    )}
                                </Stack>
                            </Stack>
                        </Group>

                        <Tooltip
                            position="top"
                            withArrow
                            arrowOffset={50}
                            arrowSize={8}
                            label={t("canteenDetails.tooltip.address_copied")}
                            opened={clipboard.copied}
                            zIndex={1011}
                        >
                            <Group
                                gap="var(--canteen-info-group-gap)"
                                onClick={copyAddress}
                                justify="space-between"
                            >
                                <Group>
                                    <IconMapPin
                                        ariaLabel={t(
                                            "canteenDetails.ariaLabels.address"
                                        )}
                                        color={companyColor}
                                        size={26}
                                    />
                                    <Stack
                                        gap={INFORMATION_CONTAINER_GAP}
                                        ml={6}
                                    >
                                        <Text
                                            className={classes.street}
                                            fw="bold"
                                            size="sm"
                                        >
                                            {canteen.street}
                                        </Text>
                                        <Text
                                            className={classes["compact-stack"]}
                                            size="sm"
                                        >
                                            {canteen.postalCode} {BERLIN_STRING}
                                        </Text>
                                    </Stack>
                                </Group>

                                <ActionIcon variant="transparent">
                                    <IconCopy
                                        ariaLabel={t(
                                            "canteenDetails.ariaLabels.copyAddress"
                                        )}
                                        size={ICON_SIZE}
                                        color={
                                            isDarkMode
                                                ? "var(--mantine-color-white-4)"
                                                : ""
                                        }
                                    />
                                </ActionIcon>
                            </Group>
                        </Tooltip>

                        {canteen.phoneNumber && (
                            <Group gap="var(--canteen-info-group-gap)">
                                <IconPhone
                                    ariaLabel={t(
                                        "canteenDetails.ariaLabels.phoneNumber"
                                    )}
                                    size={ICON_SIZE}
                                />
                                <Text
                                    className={classes.link}
                                    size="sm"
                                    component="a"
                                    fw="bold"
                                    href={`tel:${
                                        BERLIN_AREA_CODE +
                                        canteen.phoneNumber.replaceAll(" ", "")
                                    }`}
                                >
                                    {BERLIN_AREA_CODE} {canteen.phoneNumber}
                                </Text>
                            </Group>
                        )}

                        <Group gap="var(--canteen-info-group-gap)">
                            <AccessibleIcon
                                ariaLabel={t(
                                    "canteenDetails.ariaLabels.accessible"
                                )}
                                size={ICON_SIZE}
                            />
                            <Text size="sm" ml={12}>
                                {canteen.accessible
                                    ? t("canteenDetails.accessible")
                                    : t("canteenDetails.notAccessible")}
                            </Text>
                        </Group>

                        <CollapsibleInfoItem
                            icon={
                                <AccessIcon
                                    ariaLabel={t(
                                        "canteenDetails.ariaLabels.access"
                                    )}
                                    size={ICON_SIZE}
                                />
                            }
                            title={canteen.access.summary}
                            description={canteen.access.details}
                            openAriaLabel={t(
                                "canteenDetails.ariaLabels.openAccessInfo"
                            )}
                            closeAriaLabel={t(
                                "canteenDetails.ariaLabels.closeAccessInfo"
                            )}
                        />

                        <CollapsibleInfoItem
                            icon={
                                <PaymentIcon
                                    ariaLabel={t(
                                        "canteenDetails.ariaLabels.payment"
                                    )}
                                    size={ICON_SIZE}
                                />
                            }
                            title={canteen.payment.paymentMethods.join(", ")}
                            description={canteen.payment.details}
                            openAriaLabel={t(
                                "canteenDetails.ariaLabels.openPaymentInfo"
                            )}
                            closeAriaLabel={t(
                                "canteenDetails.ariaLabels.closePaymentInfo"
                            )}
                        />
                    </Stack>
                </ScrollArea.Autosize>

                <BottomSheetFooter>
                    <SimpleGrid spacing={8} cols={1}>
                        <Button
                            color={companyColorButton}
                            onClick={onShowRoute}
                            text={t("canteenDetails.showRoute")}
                            rightSectionAbsoluteText={getPrettyDistance(
                                canteen.beelineDistance
                            )}
                            disabled={!hasCoords}
                        />

                        {canteen.url ? (
                            <Button
                                color={companyColorButton}
                                onClick={() => {
                                    window.open(canteen.url);
                                }}
                                variant="outline"
                                text={t("canteenDetails.goToMenu")}
                                leftSection={
                                    <ArrowRightUpDiagonalIcon
                                        ariaLabel={t("canteenDetails.goToMenu")}
                                        size={ICON_SIZE}
                                    />
                                }
                            />
                        ) : (
                            <Tooltip
                                position="top"
                                withArrow
                                offset={-7}
                                arrowOffset={50}
                                arrowSize={8}
                                label={t(
                                    "canteenDetails.tooltip.menu_disabled"
                                )}
                                events={{
                                    hover: true,
                                    focus: false,
                                    touch: true,
                                }}
                                zIndex={1011}
                            >
                                <Group>
                                    <Button
                                        onClick={(event) => {
                                            event.preventDefault();
                                        }}
                                        variant="outline"
                                        disabledWithEvents
                                        text={t("canteenDetails.goToMenu")}
                                        leftSection={
                                            <ArrowRightUpDiagonalIcon
                                                ariaLabel={t(
                                                    "canteenDetails.goToMenu"
                                                )}
                                                size={ICON_SIZE}
                                                color={
                                                    colorScheme
                                                        ? "var(--mantine-color-gray-5)"
                                                        : "var(--mantine-color-gray-7)"
                                                }
                                            />
                                        }
                                    />
                                </Group>
                            </Tooltip>
                        )}
                    </SimpleGrid>
                </BottomSheetFooter>
            </>
        </BottomSheet>
    );
}

export default CanteenDetails;
