import React, { ReactElement, useState, useEffect, useContext } from "react";
import { Switch, ScrollArea, Stack, Box, Tooltip } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import CanteenList from "../../projectComponents/CanteenList/CanteenList";
import classes from "./ListViewPage.module.css";
import { Canteen } from "../../services/canteensService";
import CanteenDetails from "../../bottomSheetPages/CanteenDetails/CanteenDetails";
import { CanteenContext } from "../../providers/CanteenProvider";
import { LocationContext } from "../../providers/LocationProvider";
import { BASE_ROUTE } from "../../navigation/routes";
import PageHeader from "../../projectComponents/PageHeader/PageHeader";

const TOOLTIP_DISPLAY_TIME = 1500;

function ListViewPage(): ReactElement {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { height: viewportHeight } = useViewportSize();
    const [fixedAreaHeight, setFixedAreaHeight] = useState(0);
    const { selectedCanteen, setSelectedCanteen, setShowRoute } =
        useContext(CanteenContext);

    const { hasCoords } = useContext(LocationContext);
    const [sortByDistance, setSortByDistance] = useState(hasCoords);

    const [isSortingTooltipOpen, isSortingTooltipOpenHandlers] = useDisclosure(
        false,
        {
            onOpen: () =>
                setTimeout(() => {
                    isSortingTooltipOpenHandlers.close();
                }, TOOLTIP_DISPLAY_TIME),
        }
    );

    useEffect(() => {
        setSortByDistance(hasCoords);
    }, [hasCoords]);

    return (
        <>
            <Stack gap={0} className={classes.container}>
                <PageHeader
                    pageTitle={t("canteenListView.title")}
                    onGetHeight={(newHeight: number) => {
                        setFixedAreaHeight(newHeight);
                    }}
                />
                <ScrollArea.Autosize
                    mah={viewportHeight - fixedAreaHeight}
                    type="auto"
                    mr={5}
                >
                    <Stack p={16} maw={700} mx="auto">
                        <Tooltip
                            label={t(
                                "canteenListView.sortSwitch.disabledTooltip"
                            )}
                            multiline
                            w={200}
                            position="bottom"
                            withArrow
                            opened={isSortingTooltipOpen}
                            disabled={hasCoords}
                        >
                            <Box
                                w="fit-content"
                                onClick={isSortingTooltipOpenHandlers.open}
                            >
                                <Switch
                                    label={t(
                                        "canteenListView.sortSwitch.distance"
                                    )}
                                    labelPosition="left"
                                    size="md"
                                    color="black"
                                    checked={sortByDistance}
                                    onChange={(event) =>
                                        setSortByDistance(
                                            event.currentTarget.checked
                                        )
                                    }
                                    disabled={!hasCoords}
                                />
                            </Box>
                        </Tooltip>
                        <CanteenList
                            sortAlphabetically={!sortByDistance}
                            onClickHandler={(canteen: Canteen) =>
                                setSelectedCanteen(canteen)
                            }
                        />
                    </Stack>
                </ScrollArea.Autosize>
            </Stack>

            <CanteenDetails
                canteen={selectedCanteen}
                onClose={() => setSelectedCanteen(null)}
                onShowRoute={() => {
                    setShowRoute(true);
                    navigate(BASE_ROUTE);
                }}
            />
        </>
    );
}

export default ListViewPage;
