import {
    Divider,
    SimpleGrid,
    Title,
    useComputedColorScheme,
} from "@mantine/core";
import React, { ReactElement, useEffect, useState } from "react";
import Sheet from "react-modal-sheet";
import { motion } from "framer-motion";
import classes from "./BottomSheet.module.css";

const ANIMATION_OPTIONS = { duration: 0.2 };

type ResizeObservedDivProps = {
    children: React.ReactNode;
};

// this helper component might need to be moved!
function ResizeObservedDiv({ children }: ResizeObservedDivProps): ReactElement {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>(0);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const observedHeight = entries[0].contentRect.height;
            setHeight(observedHeight);
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <motion.div
            style={{ height, overflow: "auto" }}
            animate={{ height }}
            transition={ANIMATION_OPTIONS}
        >
            <div ref={containerRef}>{children}</div>
        </motion.div>
    );
}

type BottomSheetProps = {
    isOpen: boolean;
    onClose: () => void;
    children: ReactElement | null;
    headerTitle?: string;
    showBackdrop?: boolean;
    closeOnBackdropClick?: boolean;
    closeOnDrag?: boolean;
    bringToFront?: boolean;
    disableDrag?: boolean;
    contentMarginStyle?: React.CSSProperties;
    backdropAriaLabel?: string;
};

function BottomSheet({
    isOpen,
    children,
    onClose,
    headerTitle = "",
    showBackdrop = true,
    closeOnBackdropClick = true,
    closeOnDrag = true,
    bringToFront = false,
    disableDrag = false,
    contentMarginStyle,
    backdropAriaLabel,
}: BottomSheetProps): ReactElement {
    const colorScheme = useComputedColorScheme("light");

    const backgroundColor =
        colorScheme === "dark"
            ? "var(--mantine-color-dark-6)"
            : "var(--mantine-color-white-0)";

    return (
        <Sheet
            isOpen={isOpen}
            onClose={onClose}
            disableDrag={disableDrag}
            detent="content-height"
            style={{
                zIndex: bringToFront ? 1010 : 1001,
            }}
        >
            <Sheet.Container
                className={classes.sheet}
                /* react-modal-sheet applies a white background via the style attribute;
                that needs override via !important */
                style={{ backgroundColor }}
            >
                <ResizeObservedDiv>
                    <Sheet.Header disableDrag={!closeOnDrag}>
                        <SimpleGrid spacing={8} className={classes.header}>
                            {closeOnDrag && <hr className={classes.handle} />}
                            {headerTitle !== "" && (
                                <motion.div>
                                    <Title
                                        order={3}
                                        style={{ textAlign: "center" }}
                                    >
                                        {headerTitle}
                                    </Title>
                                    <Divider size="sm" />
                                </motion.div>
                            )}
                        </SimpleGrid>
                    </Sheet.Header>
                    <Sheet.Content disableDrag>
                        <div
                            className={classes.container}
                            style={contentMarginStyle}
                        >
                            {children}
                        </div>
                    </Sheet.Content>
                </ResizeObservedDiv>
            </Sheet.Container>
            <Sheet.Backdrop
                aria-label={backdropAriaLabel}
                className={
                    showBackdrop ? classes.backdrop : classes["no-backdrop"]
                }
                onTap={() => {
                    if (closeOnBackdropClick) {
                        // calling onClose is necessary, because the onClose prop
                        // of the Sheet component only applies when the user closes the sheet,
                        // not when open is set to false programmatically
                        onClose();
                    }
                }}
            />
        </Sheet>
    );
}

export default BottomSheet;
