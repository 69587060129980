import React, { ReactElement, useContext } from "react";
import { useMantineTheme } from "@mantine/core";
import L from "leaflet";
import { Marker } from "react-leaflet";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { Canteen, getCompanyColor } from "../../services/canteensService";
import { ReactComponent as IconPin } from "../../assets/icons/location-marker-line.svg";
import { CanteenContext } from "../../providers/CanteenProvider";

const ICON_HEIGHT = 38;
const ICON_WIDTH = 29;

type CanteenMarkerProps = {
    canteen: Canteen;
    onClick: (canteen: Canteen) => void;
};

function CanteenMarker({ canteen, onClick }: CanteenMarkerProps): ReactElement {
    const { t } = useTranslation();
    const { selectedCanteen } = useContext(CanteenContext);

    const theme = useMantineTheme();

    const pinColor = getCompanyColor(theme, canteen.owner);

    const marker = (
        <IconPin
            aria-label={t("map.ariaLabels.openCanteenDetails", {
                canteenName: canteen.name,
            })}
            fill={pinColor}
            height={ICON_HEIGHT}
            width={ICON_WIDTH}
        />
    );

    let markerOpacity = 1;
    if (selectedCanteen != null && selectedCanteen?.id !== canteen.id) {
        markerOpacity = 0.4;
    }

    const canteenPinIcon = new L.DivIcon({
        html: renderToString(marker),
        iconSize: new L.Point(ICON_WIDTH, ICON_HEIGHT),
        className: "",
    });

    return (
        <Marker
            position={{
                lat: canteen.coordinates[0],
                lng: canteen.coordinates[1],
            }}
            opacity={markerOpacity}
            icon={canteenPinIcon}
            eventHandlers={{ click: () => onClick(canteen) }}
        />
    );
}

export default CanteenMarker;
